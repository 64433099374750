<template>
  <div class="news-container">
      <div class="partyBuilding-container-pic">
      <img
        src="https://image.bookgo.com.cn/webculture/jm/partyBuilding/partyBuilding.png"
        alt=""
      />
    </div>
    <div class="news-content">
      

      <div class="tab-secondNav" v-if="secondNav.length > 0" style="margin-bottom:0.2rem">
        <ul class="tab-secondNav-choose-item">
          <li
            v-for="(item, i) in secondNav"
            :key="i"
            :class="{ tabActivesecondNav: cur2 == item.id }"
            @click="changeSecondNav(item, i)"
          >
            {{ item.name }}
            <span :class="{ tabActivesecondNavSpan: cur2 == item.id }">
              <span :class="{ tabActivesecondNavSubSpan: cur2 == item.id }">
              </span>
            </span>
          </li>
        </ul>
      </div>

      <div class="tab-subSwitch" v-if="subTypeList.length > 0">
        <ul class="tab-subSwitch-choose-item">
          <li
            v-for="(item, i) in subTypeList"
            :key="i"
            :class="{
              tabActive: cur == item.id,
              imgType: item.label_name.length > 4,
            }"
            @click="changeTab(item.id, i)"
          >
            {{ item.label_name }}
          </li>
        </ul>
      </div>

      <div class="tab-data">共{{ totalPage }}页 | {{ total }}条数据</div>
      
      <common-list
      :list="tabMain"
      :inactiveSrc="
        'https://image.bookgo.com.cn/webculture/jm/partyBuilding/partyBuilding-inactive.png'
      "
      :activeSrc="
        'https://image.bookgo.com.cn/webculture/jm/partyBuilding/partyBuilding-active.png'
      "
    />

      <!-- 分页组件 -->
      <Pagination
        :total="total"
        @pagination="getInfoList(1)"
        :page.sync="page"
        :limit.sync="limit"
        v-if="tabMain && tabMain.length !== 0"
      />
    </div>
  </div>
</template>

<script>
import { cateList, list } from "../../api/article";
import { navChildList } from "../../api/header/header";
import moment from "moment";
import Pagination from "@/components/Pagination/index.vue";
import commonList from "@/views/components/commonList.vue";
export default {
  name: "news",
  data() {
    return {
      current: 0,
      cur: 0,
      seccur: -1,
      cur2: -1,
      total: 0,
      aid: process.env.VUE_APP_AID,
      page: 1,
      havChild: 0,
      navId: "",
      id: "",
      cat_id: "",
      limit: 10,
      listLoading: true,
      subTypeList: [],
      childTypeList: [],
      tabMain: [],
      secondNav: [],
      allChildCat: [],
      catID: 0,
      name: "",
    };
  },
  components: {
    Pagination,
    commonList,
  },
  mounted() {
    window.scroll(0, 0);
    this.page = 1;
    this.id = this.$route.query.id;
    this.cid = this.$route.query.cid;
    this.column_id = this.$route.query.column_id;
    if (this.cid) {
      this.cur2 = this.cid;
    }
    this.getChildNav();
    this.getInfoList();
  },
  computed: {
    totalPage() {
      return Math.ceil(this.total / this.limit);
    },
  },
  watch: {
    $route(newV) {
      window.scroll(0, 0);
      this.page = 1;
      this.cur = 0;
      this.id = newV.query.id;
      this.column_id = newV.query.column_id;
      this.cid = newV.query.cid;
      if (this.cid) {
        this.cur2 = this.cid;
      }
      this.secondNav = [];
      this.subTypeList = [];
      this.getInfoList();
      this.getChildNav();
    },
  },
  methods: {
    changeSecondNav(item) {
      //获取导航下的分类
      if (item.url_type == 1 && item.url) {
        window.open(item.url, "_blank");
      } else {
        if (item.content_id == 0) {
          this.$router.push(
                `/${item.tpl_code}?id=${this.$route.query.id}&cid=${item.id}&column_id=${item.column_id}`
              );
          //   let routeUrl = this.$router.resolve({
          //   path: `/${item.tpl_code}`,
          //   query: {
          //     id: this.$route.query.id,
          //     cid: item.id,
          //     column_id: item.column_id,
          //   },
          // });
          // window.open(routeUrl.href, "_blank");
        } else {
          this.$router.push(
                `/${item.tpl_code}/detail?tid=${item.content_id}&id=${this.$route.query.id}&cid=${item.id}&column_id=${item.column_id}`
              );
          // let routeUrl = this.$router.resolve({
          //   path: `/${item.tpl_code}/detail`,
          //   query: {
          //     tid: item.content_id,
          //     id: this.$route.query.id,
          //     cid: item.id,
          //     column_id: item.column_id,
          //   },
          // });
          // window.open(routeUrl.href, "_blank");
        }
      }
    },
    getInfoList() {
      let params = {
        aid: this.aid,
        page: this.page,
        page_size: this.limit,
        app_type: 1,
        column_id: this.column_id,
      };
      if (parseInt(this.cur) > 0) {
        params.label_id = this.cur;
      }
      console.log(params);

      this.listLoading = true;
      list(params).then((res) => {
        console.log(res);
        this.listLoading = false;
        this.tabMain = res.data.list;
        res.data.list.forEach((item) => {
          item.publish_time = moment(item.publish_time * 1000).format(
            "YYYY年MM月DD日"
          );
        });
        if (res.data.label_list.length > 0) {
          this.subTypeList = res.data.label_list;
          this.subTypeList.unshift({
            id: 0,
            label_name: "全部",
          });
        }
        this.total = res.data.count;
      });
    },
    getChildNav() {
      let params = {
        aid: this.aid,
        pid: this.$route.query.id,
        app_type: 1,
      };

      navChildList(params).then((res) => {
        console.log(res);
        // this.secondNav = res.data
        if (res.code == 0) {
          this.secondNav = res.data;
        //   console.log("secondNav", this.secondNav);
        }
      });
    },
    changeTab(id, i) {
      this.page = 1;
      this.cur = id;
      this.getInfoList();
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/cmsCommon_party_index.scss";
.tab-secondNav-choose-item {
  font-size: 0.17rem;
  border-bottom: 1px solid #d5d5d5;
  padding-bottom: 0.14rem;
}
.tab-secondNav-choose-item li {
  cursor: pointer;
  // margin-left: 0.46rem;
}
.tab-secondNav-choose-item li:not(:first-child) {
  cursor: pointer;
  margin-left: 0.46rem;
}
.tabActivesecondNav {
  color: #761f1e;
  position: relative;
}

.tabActivesecondNavSpan {
  width: 100%;
  height: 3px;
  display: inline-block;
  position: absolute;
  bottom: -0.14rem;
  left: 0rem;
}
.tabActivesecondNavSubSpan {
  width: 80%;
  background: #991c10;
  height: 3px;
  display: inline-block;
  position: absolute;
  animation: 0px;
  margin: auto;
  left: 0px;
  right: 0px;
}
.tab-subSwitch {
  margin-top: 0.33rem;
}
.news-container {
  padding-top: 0.3rem;
}
.partyBuilding-container-pic {
    width: 6.6rem;
    height: 1.25rem;
    position: absolute;
    top: 0;
    right: 3.6rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
</style>
